<template>
  <div class="n4">
    <h1 class="n4-title">{{ title }}</h1>
    <p class="n4-info">{{ info }}</p>
    <swiper
      class="swiper"
      :options="swiperOption"
      ref="mySwiper"
      @someSwiperEvent="callback"
    >
      <!-- slides -->
      <swiper-slide
        class="swiper-item"
        v-for="(item, index) of content"
        :key="index"
      >
        <div>
          <p class="n4-date">{{ item.date }}</p>
          <p class="n4-caption">{{ item.caption }}</p>
          <div class="n4-view">
            <a class="n4-icon" :href="item.url">more</a>
          </div>
          <p class="n4-desc">{{ item.desc }}</p>
          <div class="n4-img">
            <img :src="item.imgUrl" alt />
          </div>
        </div>
      </swiper-slide>
      <!-- Optional controls -->
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
      <div class="swiper-scrollbar" slot="scrollbar"></div>
    </swiper>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "n4",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        scrollbar: {
          el: ".swiper-scrollbar",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      currentEnter: null,
      currentLeave: null,
      title: "新闻动态",
      info: "御之安科技，网络安全领域崭露头角。",
      content: [
         {
          imgUrl: require("../../assets/img/index/651.png"),
          caption: "聚焦主业，深耕细作",
          date: "2024-07-26",
          desc: "近日,工信部发布了《四川省2024年第一批拟入库科技型中小企业名单》，成都御之安科技有限公司成功入库！",
          url: "https://mp.weixin.qq.com/s/p04eB_IW33onzd0eu-gRVQ",
        },
         {
          imgUrl: require("../../assets/img/index/650.png"),
          caption: "欢迎劳专委莅临御之安进行参观和交流",
          date: "2024-07-10",
          desc: "7月10日，劳动关系专业委员会（以下简称“劳专委”）黄主任携副主任单位三位老师莅临御之安参观交流，共同推动企业和员工的共同进步与发展。",
          url: "https://mp.weixin.qq.com/s/snboakL11XBlaayVo21chw",
        },
        {
          imgUrl: require("../../assets/img/index/649.png"),
          caption: "御之安：创新路，技术行！",
          date: "2024-06-24",
          desc: "近年来，御之安在持续发展中展现了显著成效，公司在新产品研发上取得了卓越进展。御之安积极参与各类创新创业比赛，提升团队的创新思维与能力。",
          url: "https://mp.weixin.qq.com/s/jUiWD9PvQb3dNEmBmGw1SA",
        },
        {
          imgUrl: require("../../assets/img/index/643.jpg"),
          caption: "携手同行，共建防线|2024四川大学网络安全演练致谢御之安",
          date: "2024-04-29",
          desc: "近日，御之安收到四川大学的感谢信，信中高度肯定和赞扬了成都御之安科技在2024年四川大学网络安全演练中的优异表现。",
          url: "https://mp.weixin.qq.com/s/qaFN2QsTkGMYMVOs5XS1gw",
        },
        {
          imgUrl: require("../../assets/img/index/644.png"),
          caption: "御之安招聘|御之安受邀参加西南科技大学2024年春季双选会",
          date: "2024-04-11",
          desc: "四月春意盎然，大地回春。御之安科技很荣幸受到西南科技大学的邀请，参与这次盛大的2024年春季双选会招聘，感受来自西科大学子的生机与活力！",
          url: "https://mp.weixin.qq.com/s/3-ccA-XkFgwb--KyZrQV5A",
        },
        {
          imgUrl: require("../../assets/img/index/645.png"),
          caption: "表彰卓越，共筑安全屏障——来自客户的感谢信",
          date: "2024-04-08",
          desc: "御之安科技以专业、高效的态度，与安徽邮电职业技术学院携手合作，共同致力于保障网络安全，确保其网络系统在护网期间的平稳运行。",
          url: "https://mp.weixin.qq.com/s/SsaNd-I0kQNtNabQze_-5g",
        },
        {
          imgUrl: require("../../assets/img/index/646.png"),
          caption: "御之安科技助力2024NKCTF比赛圆满举办！",
          date: "2024-04-03",
          desc: "由中通服科信信息技术有限公司主办，N0wayBack战队承办，以及江苏海事局、金盾检测技术股份有限公司、成都御之安科技有限公司等支撑的NKCTF系列赛事于2024年3月22日完美落幕。",
          url: "https://mp.weixin.qq.com/s/S4dq_ndZxalcJ-IuKee0mA",
        },
        {
          imgUrl: require("../../assets/img/index/647.png"),
          caption: "御之安科技庆祝2024成都人力资源与劳动关系大会圆满成功！",
          date: "2024-03-27",
          desc: "2024年“人力资源协同产业发展暨和谐劳动关系共建大会”于成都市青羊区城市名人酒店名人厅圆满举办成功。",
          url: "https://mp.weixin.qq.com/s/N81gf3M0aXyJJKAopm0ulA",
        },
        {
          imgUrl: require("../../assets/img/index/648.png"),
          caption: "2024成都市人力资源与劳动关系重要盛会即将启幕！",
          date: "2024-03-15",
          desc: "御之安科技诚挚邀请您报名参与本次大会，与业界同仁共襄盛举，共同推动人力资源与产业发展的深度融合，为构建更加和谐的劳动关系贡献智慧与力量。",
          url: "https://mp.weixin.qq.com/s/tYLxyZFNABgi9jiqvk9NjA",
        },
        {
          imgUrl: require("../../assets/img/index/641.jpg"),
          caption: "喜报！御之安成功入选2023年第四季度创新型中小企业名单",
          date: "2023-12-28",
          desc: "近日，成都市经济和信息化局官网公示2023年第四季度创新型中小企业名单，共有807家企业入选。御之安科技成功入选本季度创新型中小企业名单！",
          url: "https://mp.weixin.qq.com/s/68T_cd0xre_3Hv3zfqzbkg",
        },
        {
          imgUrl: require("../../assets/img/index/642.png"),
          caption: "御之安在2023四川省网络信息安全大赛个人赛和团体赛均获二等奖",
          date: "2023-10-26",
          desc: "“2023年四川省网络与信息安全技能大赛”正式启动，大赛以“做网安卫士，助强国建设”为主题，旨在积极响应国家网络强国及网络安全人才战略，加快攻防兼备实战型人才培养步伐，夯实网络安全基石，提升四川省内网络安全领域整体能力水平。",
          url: "https://mp.weixin.qq.com/s/xz5jZnboncCLhZM2Gm3cyw",
        },
        {
          imgUrl: require("../../assets/img/index/641.png"),
          caption: "2023年国家网络安全周：筑起网络安全的铜墙铁壁",
          date: "2023-09-11",
          desc: "自数字时代的曙光初现，互联网已经深入到我们日常生活的每一个角落。它为我们带来了无尽的便利，但同时也带来了一系列的网络安全威胁。",
          url: "https://mp.weixin.qq.com/s/DFAULEaIaIHAYKTRuGh57Q",
        },
        {
          imgUrl: require("../../assets/img/index/640.png"),
          caption: "御之安科技获2023网络安全技能挑战赛创新创业三等奖",
          date: "2023-09-04",
          desc: "由四川省互联网信息办公室指导的，成都市互联网信息办公室与成都高新技术产业开发区管理委员会主办的2023”巅峰极客“网络安全技能挑战赛火热开展",
          url: "https://mp.weixin.qq.com/s/KVjHr4IVzpvrmlWt8TWLUw",
        },
        {
          imgUrl: require("../../assets/img/index/640.jpg"),
          caption: "成都御之安加入首批全国网络安全产教融合共同体",
          date: "2023-09-01",
          desc: "成都御之安科技成为首批加入全国网络安全行业产教融合共同体的科研机构，为促进网络安全产业高质量发展献上绵薄之力！",
          url: "https://mp.weixin.qq.com/s/iWgK17xfeC-uD-nBIEjJGQ",
        },
        {
          imgUrl: require("../../assets/img/index/n4-13.png"),
          caption: "护⽹⾏动20230810晚报",
          date: "2023-08-10",
          desc: "网络安全行动通报：'护网行动 20230810'，我们在守护网络安全的道路上继续前行。感谢您的关注与参与。",
          url: "https://mp.weixin.qq.com/s/3lWMP02raML5Zj9u8frHiA",
        },
        {
          imgUrl: require("../../assets/img/index/n4-12.png"),
          caption: "护网行动晚报 - 漏洞情报更新",
          date: "2023-08-09",
          desc: "网络安全行动通报：'护网行动 20230809'已更新漏洞情报，共筑坚固防线。感谢您的关注与积极参与。",
          url: "https://mp.weixin.qq.com/s/BQribwRLB9n7OB86Bni8-Q",
        },
        {
          imgUrl: require("../../assets/img/index/n4-11.png"),
          caption: "HW第一批IP封禁名单通报",
          date: "2023-08-08",
          desc: "封禁通告：2023年8月8日，我司发布首批HWIP封禁名单，以强化网络安全。感谢您的配合与理解。",
          url: "https://mp.weixin.qq.com/s/lPXSowxcjDezLDwxx_mNjg",
        },
        {
          imgUrl: require("../../assets/img/index/n4-10.png"),
          caption: "御之安科技助力赛宁网安开启2023InForSec夏令营",
          date: " 2023-08-02",
          desc: "由赛宁网安举办的2023InForSec夏令营课程即将开启，御之安科技的技术总监陈铭瀚担任本次夏令营Xman（CTF）班讲师，致力于提高学员CTF实战技能。！",
          url: "https://mp.weixin.qq.com/s/Xz2VwTHdM5J-LW8RJjd1ZA",
        },
        {
          imgUrl: require("../../assets/img/index/n4-9.jpg"),
          caption: "我们两岁啦,御之安2周年庆!",
          date: "2023-07-24",
          desc: "无实战，不安全！御之安成立于2021年，公司位于四川成都市团队成员来自于各大安全厂商及头部互联网企业安全实验室，拥有多年一线攻防对抗的工作经验，多次在大型攻防演练中名列前茅",
          url: "https://mp.weixin.qq.com/s/5UQ82Y9njf1NzKqI921Bcg",
        },
        {
          imgUrl: require("../../assets/img/index/n4-8.webp"),
          caption: "2023护网行动招聘",
          date: "2023-07-18",
          desc: "参与'2023护网行动'，共建安全网络。招募中，期待您的加入，共同守护数字世界的安全与繁荣！",
          url: "https://mp.weixin.qq.com/s/b_SwVU49wZXa7kni8SiPbg",
        },
        {
          imgUrl: require("../../assets/img/index/n4-7.jpg"),
          caption: "2023HVV行动招募",
          date: " 2023-04-20",
          desc: "'2023HVV行动'热诚招募中！加入我们，开启改变与成长之旅！携手创造美好，期待您的踊跃参与！共创未来！",
          url: "https://mp.weixin.qq.com/s/pkpp9Eo2vHAxK_6DP8japg",
        },
        {
          imgUrl: require("../../assets/img/index/n4-6.jpg"),
          caption: "第七届XCTF国际网络攻防联赛总决赛完美举行！",
          date: "2023-03-31",
          desc: "本届大赛由XCTF国际网络攻防联赛组委会发起，国家创新与发展战略研究会主办，赛宁网安承办并提供技术支撑，r3kapig战队命题。",
          url: "https://mp.weixin.qq.com/s/OxC8aPSz-zJYh2ZYu-J-WA",
        },
        {
          imgUrl: require("../../assets/img/index/n4-5.jpg"),
          caption: "SQL注入靶场保姆级教程(二)",
          date: "2023-01-11",
          desc: "继续深入学习SQL注入技巧，第二部分保姆级教程。掌握高级攻防策略，构建坚固的防御墙。",
          url: "https://mp.weixin.qq.com/s/IeI09378I-EzbI00U6HPYA",
        },
        {
          imgUrl: require("../../assets/img/index/n4-5.jpg"),
          caption: "SQL注入靶场保姆级教程(一)",
          date: " 2022-11-25",
          desc: "从零开始学习，掌握SQL注入技巧。详细教程带你逐步攻克SQL注入靶场，建立基础防御知识。",
          url: "https://mp.weixin.qq.com/s/mqlJgGLBGbiJUXhbk154ug",
        },
        {
          imgUrl: require("../../assets/img/index/n4-4.jpg"),
          caption: "御之安代码审计精品课程",
          date: "2022-10-19",
          desc: "解密代码安全，御之安代码审计课程，尽在精品课程中。",
          url: "https://mp.weixin.qq.com/s/MRv5MWlVWnSXD8yJudFoDA",
        },
        {
          imgUrl: require("../../assets/img/index/n4-2.jpg"),
          caption: "御之安科技招新啦",
          date: "2022-09-27",
          desc: "紧急警报！台湾遭受网络攻击？深度揭秘攻防战，立即了解背后故事、技巧与策略！成都御之安科技招新人了，快来看看有没有你心仪的岗位！",
          url: "https://mp.weixin.qq.com/s/u8tbwwiQpzaZ1FbNZwMYYA",
        },
        {
          imgUrl: require("../../assets/img/index/n4-3.png"),
          caption: "荣获“安全职业技能竞赛”团队赛初赛第二名！",
          date: "2022-09-18",
          desc: "御之安科技荣获“2022年川渝网络与信息安全职业技能竞赛”团队赛初赛第二名",
          url: "https://mp.weixin.qq.com/s/1Wao1TeuCnsXd7TValpp4g",
        },
        {
          imgUrl: require("../../assets/img/index/n4-2.jpg"),
          caption: "成都御之安科技招实习生啦",
          date: "2022-09-01",
          desc: "很多在校生都会有这样的焦虑,害怕公司不正规,上当受骗,担心自己经验不足，面试被拒不用怕！你想要的,我们都能满足你,还不赶快来看看!",
          url: "https://mp.weixin.qq.com/s/X1sSCmUpJBnwggO0JnV69g",
        },
        {
          imgUrl: require("../../assets/img/index/n4-2.png"),
          date: "2022-08-05",
          caption: "20小时让你也变成红客",
          url: "https://mp.weixin.qq.com/s/uGbQ8tta-PY5fFBkHlJ_xQ",
          desc: "紧急警报！台湾遭受网络攻击，20小时内蜕变红客？深度揭秘攻防战，立即了解背后故事、技巧与策略！",
        },
      ],
    };
  },
  methods: {
    imgMoveStart(index) {
      this.currentEnter = index;
      this.currentLeave = null;
    },
    imgMoveEnd(index) {
      this.currentLeave = index;
      this.currentEnter = null;
    },
    callback() {
      console.log("callback");
    },
  },
};
</script>

<style scoped>
.n4 {
  text-align: center;
  height: 700px;
  padding: 20px;
  overflow: hidden;
}
h1 {
  position: relative;
  display: inline-block;
  margin: 0 auto 20px;
  line-height: 30px;
  padding: 0 30px;
  font-weight: 400;
  font-size: 28px;
}
h1::before {
  content: "";
  width: 66px;
  height: 13px;
  background: url(../../assets/img/sofaFW/n1_r.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 5px;
  left: 100%;
}
h1::after {
  content: "";
  width: 66px;
  height: 13px;
  background: url(../../assets/img/sofaFW/n1_l.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 5px;
  right: 100%;
}
.swiper {
  margin: 30px;
}
.n4-content::after {
  content: "";
  clear: both;
  display: block;
}
.swiper-item {
  width: 360px;
  height: 500px;
  padding-top: 10px;
  text-align: center;
  font-size: 16px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(27, 43, 79, 0.2);
  margin: 0 12px 4px;
  overflow: hidden;
}
.swiper-item:hover {
  box-shadow: 0 0 10px 0 rgba(27, 43, 79, 0.4);
}
.n4-date {
  opacity: 0.8;
  font-family: Roboto;
  font-weight: 800;
  font-size: 12px;
  color: #1b2b4f;
  letter-spacing: 2px;
  text-align: center;
  line-height: 16px;
  margin: 0;
}
.n4-caption {
  font-size: 24px;
  color: #1b2b4f;
  text-align: center;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  height: 80px;
  -webkit-box-orient: vertical;
}
.n4-desc {
  padding: 0 10px;
  opacity: 0.6;
  font-size: 14px;
  text-indent: 1rem;
  color: #1b2b4f;
  text-align: left;
  line-height: 28px;
  position: absolute;
  bottom: 240px;
  font-family: PingFangSC;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  height: 84px;
  -webkit-box-orient: vertical;
}
.n4-icon {
  background: url(../../assets/img/index/n4-icon.png) right center no-repeat;
  padding-right: 5px;
  font-size: 12px;
  line-height: 12px;
  color: #d00000;
  letter-spacing: 3px;
  text-align: center;
  font-family: Roboto;
  font-weight: 700;
  text-decoration: none;
}
.n4-view {
  position: absolute;
  text-align: center;
  top: 160px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.n4-img {
  width: 360px;
  height: 210px;
  position: absolute;
  bottom: 5px;
  overflow: hidden;
}
.n4-img img {
  width: 360px;
  height: 210px;
  transition-duration: 0.5s;
}
.n4-img img:hover {
  transform: scale(1.1);
}
</style>

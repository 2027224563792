<template>
  <div class="newAll">
    <div class="alls">
      <div class="alls">
        <div :class="showNav == true ? 'first' : 'newFirst'">
          <div class="firstLeft">
            <img src="../../../../assets/img/logo/logo.png" alt="" />
            <h2>御之安科技</h2>
          </div>
          <div class="firstMiddle">
            <el-menu
              ref="elmenudemo"
              mode="horizontal"
              :default-active="$route.path"
              :router="true"
              class="menuDemos"
              @select="handleSelect"
              background-color="#F0F0F0"
              text-color="#000"
              active-text-color="#2a6fa5"
            >
              <el-menu-item index="/">首页</el-menu-item>
              <el-submenu index="2">
                <template slot="title">安全产品</template>
                <el-menu-item
                  index="/pc/mappingCenter"
                  style="
                    width: 220px;
                    font-family: PingFang SC, Helvetica Neue, Helvetica,
                      Hiragino Sans GB, Microsoft YaHei, 微软雅黑, Arial,
                      sans-serif;
                  "
                  >虚影-数据隐蔽防御系统</el-menu-item
                >
                <el-menu-item index="/pc/ctfCenter" style="width: 220px"
                  >御之安CTF竞赛平台</el-menu-item
                >
                <el-menu-item index="/pc/redBlueCenter" style="width: 220px"
                  >御之安知识培训平台</el-menu-item
                >
                <el-menu-item
                  index="/pc/vulnerabilityCenter"
                  style="width: 220px"
                  >御之安Windows靶场平台</el-menu-item
                >
              </el-submenu>
              <el-submenu index="3">
                <template slot="title">安全服务</template>
                <el-menu-item
                  index="/pc/fencingOperations"
                  style="padding-left: 20px"
                >
                  护网行动</el-menu-item
                >
                <el-menu-item
                  index="/pc/safetyCompetition"
                  style="padding-left: 20px"
                >
                  安全竞赛</el-menu-item
                >
                <el-menu-item index="/pc/codeAudit" style="padding-left: 20px"
                  >代码审计</el-menu-item
                >
                <el-menu-item
                  index="/pc/emergencyResponse"
                  style="padding-left: 20px"
                  >应急响应</el-menu-item
                >
                <el-menu-item
                  index="/pc/securityOperation"
                  style="padding-left: 20px"
                  >安全运维</el-menu-item
                >
                <el-menu-item
                  index="/pc/securityReinforcement"
                  style="padding-left: 20px"
                  >安全加固</el-menu-item
                >
                <!-- <el-menu-item index="3-8" style="padding-left: 20px"
                      >风险评估</el-menu-item
                    > -->
                <el-menu-item
                  index="/pc/securityAdvisory"
                  style="padding-left: 20px"
                  >安全咨询</el-menu-item
                >
              </el-submenu>
              <el-submenu index="4">
                <template slot="title">赛事服务</template>
                <el-menu-item index="4-1" style="padding-left: 20px"
                  >比赛入口</el-menu-item
                >
                <el-menu-item index="4-2" style="padding-left: 20px"
                  >赛事承办</el-menu-item
                >
                <el-menu-item index="4-2" style="padding-left: 20px"
                  >赛事复盘</el-menu-item
                >
              </el-submenu>
              <el-submenu index="5">
                <template slot="title">校企合作</template>
                <el-menu-item
                  index="/pc/safetyTraining"
                  style="padding-left: 20px"
                  >安全培训</el-menu-item
                >
                <el-menu-item
                  index="/pc/platformEquipment"
                  style="padding-left: 20px"
                  >平台设备</el-menu-item
                >
              </el-submenu>
              <el-submenu index="6">
                <template slot="title">关于我们</template>
                <el-menu-item
                  index="/pc/companyProfile"
                  style="padding-left: 20px"
                  >公司介绍</el-menu-item
                >
                <el-menu-item index="/pc/joinUs" style="padding-left: 20px"
                  >加入我们</el-menu-item
                >
                <el-menu-item index="/pc/contactUs" style="padding-left: 20px"
                  >联系我们</el-menu-item
                >
              </el-submenu>
              <!-- <el-menu-item index="/pc/aboutUs">关于御之安</el-menu-item> -->
            </el-menu>
          </div>
          <!-- <div class="firstLeft11">
                <el-button type="primary">点击切换中英文</el-button>
              </div> -->
        </div>
      </div>

      <div class="newIndexFirst">
        <div class="newIndexFirstLeft">
          <!-- <h1>守护无声，保证万象</h1> -->
          <Mainbg></Mainbg>
        </div>
        <div class="newIndexFirstRight"></div>
      </div>
      <div class="newIndexThird">
        <h1>安全产品</h1>
        <div class="newIndexThird1">
          <div class="newIndexThird1left">
            <h3>御炼：CTF竞赛，实践、交流、成长。</h3>
            <h4>CTF竞赛平台</h4>
            <p>
              通过充分参与活动来获得个人的经验，并进行交流和分享，在实践活动中体悟和理解、内化相关知识，然后通过反思、总结，提升为理性知识。
            </p>
            <router-link to="/pc/ctfCenter">了解更多</router-link>
          </div>
          <div class="newIndexThird1Right">
            <img src="../../../../assets/img/index/6.jpg" alt="" />
          </div>
        </div>
      </div>
      <div class="newIndexSecond">
        <SofaServe></SofaServe>
      </div>
      <div class="newFourth">
        <NewsBanner></NewsBanner>
      </div>

      <div class="eighth">
        <h1>合作案例</h1>
        <div class="eighth1">
          <vue-seamless-scroll
            :data="this.hzList"
            :class-option="classOption"
            class="warp"
          >
            <ul class="ul-item">
              <li
                class="li-item"
                v-for="(item, index) in this.hzList"
                :key="index"
              >
                <img :src="item" alt="" />
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
        <vue-seamless-scroll
            :data="this.hzList1"
            :class-option="classOption1"
            class="warp1"
          >
            <ul class="ul-item">
              <li
                class="li-item"
                v-for="(item, index) in this.hzList1"
                :key="index"
              >
                <img :src="item" alt="" />
              </li>
            </ul>
          </vue-seamless-scroll>
      </div>
    </div>
    <div class="lasts">
      <h2>御之安科技</h2>
      <div class="lastsTop">
        <div class="lastsTop1">
          <h3>联系地址:</h3>
          <p>地址:中国(四川)成都武侯区天府三街新希望国际大厦B2座2319</p>
          <p>邮箱:admin@yuzhian.com.cn</p>
          <p>电话:+86 18381475890,+86 18770382226</p>
        </div>
        <div class="lastsTop3">
          <h3>产品中心:</h3>
          <p>
            <router-link to="/pc/mappingCenter"
              >虚影-数据隐蔽防御系统 </router-link
            >
          </p>
          <p>
            <router-link to="/pc/ctfCenter">御之安CTF竞赛平台</router-link>
          </p>
          <p>
            <router-link to="pc/redBlueCenter">御之安知识培训平台</router-link>
          </p>
          <p>
            <router-link to="/pc/vulnerabilityCenter"
              >御之安Windows靶场平台</router-link
            >
          </p>
        </div>
        <div class="lastsTop2">
          <h3>安全服务:</h3>
          <p><router-link to="/pc/safetyTraining">安全培训</router-link></p>
          <p><router-link to="/pc/codeAudit">代码审计</router-link></p>
          <p>
            <router-link to="/pc/emergencyResponse">应急响应</router-link>
          </p>
          <p>
            <router-link to="/pc/safetyCompetition">安全竞赛</router-link>
          </p>
          <p>
            <router-link to="/pc/securityReinforcement">安全加固</router-link>
          </p>
          <p>
            <router-link to="/pc/securityAdvisory">安全咨询</router-link>
          </p>
        </div>
        <div class="lastsTop2">
          <h3>校企合作:</h3>
          <p><router-link to="/pc/safetyTraining">安全培训</router-link></p>
          <p>
            <router-link to="/pc/platformEquipment">平台设备</router-link>
          </p>
        </div>
        <div class="lastsTop2">
          <h3>关于御之安:</h3>
          <p><router-link to="/pc/companyProfile">关于我们</router-link></p>
          <p><router-link to="/pc/joinUs">加入我们</router-link></p>
          <p><router-link to="/pc/contactUs">联系我们</router-link></p>
        </div>
        <div class="lastsTop6">
          <h3>企业微信:</h3>
          <img src="../../../../assets/img/logo/wechat.jpg" alt="" />
          <p>公众号:</p>
          <img src="../../../../assets/img/logo/wechat1.jpg" alt="" />
        </div>
      </div>
      <div class="lastsTop7">
        <ul>
          <li>
            <router-link to="/pc/legalStatement">法律声明</router-link>
          </li>
          <li>
            <router-link to="/pc/intellectualPropertyDescription"
              >知识产权声明</router-link
            >
          </li>
          <li>
            <router-link to="/pc/privacyProtection">隐私保护</router-link>
          </li>
        </ul>
      </div>
      <div class="lastsMiddle">
        <router-link to="/">@2021成都御之安科技有限公司</router-link>
        &nbsp;&nbsp;<a href="http://beian.miit.gov.cn/">蜀ICP备2022013491号-1</a
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <span>地址:中国(四川)自由贸易试验区成都高新区新希望国际B2座2319A</span>
      </div>
    </div>
  </div>
</template>
<script>
import { WOW } from "wowjs";
import vueSeamlessScroll from "vue-seamless-scroll";
import Mainbg from "@/components/PluginLibrary/MainBg.vue";
import SofaServe from "@/components/PluginLibrary/SofaServe.vue";
import NewsBanner from "@/components/PluginLibrary/NewsBanner.vue";
export default {
  components: {
    vueSeamlessScroll,
    Mainbg,
    SofaServe,
    NewsBanner,
  },
  data() {
    return {
      activeName: "first",
      activeIndex2: "1",
      wechatShow: false,
      bannerList: [
        require("../../../../assets/img/banner/banner-bg-4.jpg"),
        require("../../../../assets/img/banner/banner-bg-5.jpg"),
        require("../../../../assets/img/banner/footer-bg.jpg"),
      ],
      hzList: [
        require("../../../../assets/img/hzal/parter1.png"),
        require("../../../../assets/img/hzal/partner-9.png"),
        require("../../../../assets/img/hzal/partner-11.jpg"),
        require("../../../../assets/img/hzal/partner-10.webp"),
        require("../../../../assets/img/hzal/partner-1.svg"),
        require("../../../../assets/img/hzal/partner-12.jpg"),
      ],
      hzList1: [
        require("../../../../assets/img/hzal/partner-5.png"),
        require("../../../../assets/img/hzal/partner-3.png"),
        require("../../../../assets/img/hzal/partner-4.png"),
        require("../../../../assets/img/hzal/partner-6.jpg"),
        require("../../../../assets/img/hzal/partner-81.png"),
        require("../../../../assets/img/hzal/partner-12.png"),
        require("../../../../assets/img/hzal/partner-14.png"),
      ],
      showNav: true,
      map: null,
      local: null,
      mk: null,
      latitude: "",
      longitude: "",
      keyWords: "",
      popup: false,
      classOption: {
        limitMoveNum: 2,
        direction: 3,
        step: 0.5,
      },
      classOption1: {
        limitMoveNum: 2,
        direction: 2,
        step: 0.5,
      },
      terminalOutput: "Welcome to the 御之安\n", // 初始化终端内容
      inputValue: "", // 输入框的值
    };
  },

  destroy() {
    // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
    window.removeEventListener("scroll", this.scrollLength);
  },
  mounted() {
    window.addEventListener("scroll", this.scrollLength, false);
    //第一种写法，可以设置wow中属性
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      var wow = new WOW({
        boxClass: "wow", ///动画元件css类（默认为wow）
        animateClass: "animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      });
      wow.init();
    });
  },
  destroy() {},
  created() {},
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    scrollLength() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const top = document.documentElement.scrollTop;
      if (top > 122.55) {
        let opacity = top / 100;
        opacity = opacity > 1 ? 1 : opacity;
        this.showOpacity = { opacity };
        this.showNav = false;
      }
      if (top < 100) {
        this.showNav = true;
      }
    },
    aboutUs() {
      this.$router.push("/pc/companyProfile");
    },
    handleInput() {
      // 处理用户输入
      const input = this.inputValue.trim(); // 去除输入值两端的空格
      this.terminalOutput += `$ ${input}\n`; // 将输入值添加到终端内容中，并添加一个$提示符
      this.inputValue = ""; // 清空输入框
      this.processCommand(input); // 处理用户输入的命令
    },
    processCommand(command) {
      // 在这里可以根据用户输入的命令执行相应的操作，例如发送请求到后端API等
      // 这里只做简单演示
      if (command === "help") {
        this.terminalOutput += "Available commands: help, about, contact\n";
      } else if (command === "about") {
        this.terminalOutput +=
          "This is a simple terminal-like interface built with Vue 2.\n";
      } else if (command === "contact") {
        this.terminalOutput += "You can contact us at contact@example.com\n";
      } else {
        this.terminalOutput +=
          'Command not found. Type "help" to see available commands.\n';
      }
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  /*其他*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@font-face {
  font-family: "PingFang SC";
  src: url("~@/assets/fonts/PingFang SC.ttf");
}

@font-face {
  font-family: "Helvetica";
  src: url("~@/assets/fonts/HELVETI1-1.ttf");
}
@font-face {
  font-family: "微软雅黑";
  src: url("~@/assets/fonts/微软雅黑.ttf");
}
body {
  // font-family: PingFang SC,Helvetica Neue,Helvetica,Hiragino Sans GB,Microsoft YaHei,微软雅黑,Arial,sans-serif;
  font-family: "PingFang SC", "Helvetica", "微软雅黑";
  font-weight: 400;
  font-size: var(--el-font-size-base);
  -webkit-font-smoothing: antialiased;
}
@media screen and (max-width: 768px) {
  .newAll {
    width: 100%;
  }
}
/* >=992 and  */
@media screen and (min-width: 992px) {
  .newAll {
    width: 49%;
  }
}
/* >=768px and <1200px */
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .newAll {
    width: 48%;
  }
}
/* >=1200px */
@media screen and (min-width: 1200px) {
  .newAll {
    width: 33%;
  }
}

.newAll {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .alls {
    width: 100%;
    min-height: 100%;
    .terminal {
      background: linear-gradient(to right, #222, #333);
      color: #fff;
      font-family: "Courier New", monospace;
      padding: 10px;
      width: 400px;
      height: 200px;
      border-radius: 5px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
      overflow-y: auto;
      position: relative;
    }

    pre {
      margin: 0;
    }

    @keyframes blink {
      50% {
        opacity: 0;
      }
    }

    input {
      background-color: transparent;
      color: #fff;
      border: none;
      outline: none;
      font-family: "Courier New", monospace;
      width: 100%;
      padding: 0;
      margin-top: 5px;
      caret-color: #00ff00;
    }
    .newIndexFirst {
      width: 100%;
    }

    .newIndexThird {
      background-color: #f1f5ff;
      padding: 20px;
      h1 {
        text-align: center;
        margin-bottom: 20px;
      }
      .newIndexThird1 {
        display: flex;
        justify-content: center;
        align-items: center;
        .newIndexThird1Right {
          margin-left: 20px;
          img {
            width: 820px;
            height: 400px;
          }
        }
        .newIndexThird1left {
          width: 30%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          h3 {
            background: #4c84ff;
            border-radius: 1.875rem;
            color: #fff;
            padding: 10px;
            margin-bottom: 20px;
          }
          p {
            font-size: 16px;
            color: #51565d;
            margin-top: 20px;
            line-height: 26px;
            margin-bottom: 20px;
          }
          a {
            cursor: pointer;
            display: inline-block;
            line-height: 3.375rem;
            width: 10rem;
            text-align: center;
            border: 1px solid #1f75fe;
            font-size: 1.125rem;
            color: #1f75fe;
            border-radius: 1.875rem;
            transition: 0.4s;
            position: relative;
            overflow: hidden;
          }
          a:hover {
            background: #1f75fe;
            border-radius: 1.875rem;
            color: #fff;
            transition: all 0.4s ease-in-out;
          }
        }
      }
    }
    .newIndexSecond {
      margin-top: 20px;
      .newIndexSecondLeft {
        width: 800px;
        h2 {
          font-weight: 500;
          font-size: 30px;
          color: #1f2329;
          text-align: center;
        }
        .newIndexSecondLeft11 {
          display: flex;
          flex-wrap: wrap;
          width: 800px;
          margin-left: 20px;
          .newIndexSecondLeft1 {
            width: 350px;
            border: 3px solid #79797a;
            padding: 10px;
            border-radius: 10px;
            margin: 20px;
            h3 {
              font-size: 24px;
              color: #1f2329;
            }
            p {
              font-size: 16px;
              color: #51565d;
              margin-top: 20px;
              line-height: 26px;
            }
          }
        }
      }
      .newIndexSecondRight {
        width: 28%;
      }
    }
    .newFourth {
      margin-top: 20px;
      background-color: #f1f5ff;
    }

    .eighth {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      // background-color: #f1f5ff;
      h1 {
        text-align: center;
      }
      .eighth1 {
        height: 185px;
        // margin-bottom: 20px;
        .warp {
          width: 326px * 4;
          height: 185px;
          margin-bottom: 40px;
          overflow: hidden;
          ul {
            list-style: none;
            padding: 0;
            margin: 0 auto;
            // background-color: #f1f5ff;
            &.ul-item {
              display: flex;
              padding: 10px 0;
              .li-item {
                width: 316px;
                height: 135px;
                margin-right: 20px;
                line-height: 120px;
                color: #fff;
                text-align: center;
                font-size: 1.875rem;
                box-shadow: 0 4px 16px 0 rgb(179 192 231);
                flex-shrink: 0;
                box-sizing: border-box;
                padding: 20px 0;
                // border: 1px solid #000;
                border-radius: 10px;
                img {
                  width: 300px;
                  // height: 67px;
                }
              }
            }
          }
        }
      }
      .warp1 {
        width: 326px * 4;
        height: 185px;
        overflow: hidden;
        ul {
          list-style: none;
          padding: 0;
          margin: 0 auto;
          // background-color: #f1f5ff;
          &.ul-item {
            display: flex;
            padding: 10px 0;
            .li-item {
              width: 316px;
              height: 135px;
              margin-right: 20px;
              line-height: 120px;
              color: #fff;
              text-align: center;
              font-size: 1.875rem;
              box-shadow: 0 4px 16px 0 rgb(179 192 231);
              flex-shrink: 0;
              box-sizing: border-box;
              padding: 20px 0;
              // border: 1px solid #000;
              border-radius: 10px;
              img {
                width: 300px;
                // height: 67px;
              }
            }
          }
        }
      }
    }

    .form-layer {
      width: 100%;
    }
    #map {
      margin-top: 30px;
      width: 100%;
      height: 300px;
      border: 1px solid gray;
      box-sizing: border-box;
      overflow: hidden;
    }
    /deep/ .el-dialog {
      min-width: 550px;
    }
    /deep/ .el-dialog__body {
      padding: 10px;
    }
  }
  .alls {
    width: 100%;

    .first {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: #f0f0f0;
      .firstLeft {
        width: 30%;
        display: flex;
        align-items: center;
        img {
          width: 55px;
          height: 55px;
        }
        h2 {
          margin-left: 10px;
        }
      }
      .firstMiddle {
        // margin-left: 300px;
        // /deep/#newElMenu {
        //   width: 220px !important;
        // }
        // /deep/.el-menu-item {
        //   // font-size: 1.25rem;
        // }
        // /deep/.el-submenu__title {
        //   padding: 0 20px !important;
        //   // font-size: 1.25rem;
        // }
        .menuDemos {
          font-size: 4.25rem !important;
        }
      }
    }
    .newFirst {
      width: 100%;
      display: flex;
      left: 0;
      right: 0;
      top: 0;
      z-index: 99 !important;
      position: fixed;
      background-color: #f0f0f0;
      box-shadow: 0 2px 2px 0 rgb(233, 230, 230);
      justify-content: space-around;
      align-items: center;
      transition: all 0.5s;
      .firstLeft {
        width: 30%;
        display: flex;
        align-items: center;
        img {
          width: 55px;
          height: 55px;
        }
        h2 {
          margin-left: 10px;
        }
      }
      .firstMiddle {
        // font-size: 1rem !important;
        // // margin-left: 300px;
        // // background-color: #484a4e;
        // /deep/#newElMenu {
        //   width: 220px !important;
        // }
        // /deep/.el-menu-item {
        //   // font-size: 1.25rem;
        // }
        // /deep/.el-submenu__title {
        //   padding: 0 20px !important;
        //   // font-size: 1.25rem;
        // }
        // /deep/.el-menu
        //   .el-menu--popup
        //   .el-menu--popup-bottom-start
        //   .el-menu-item {
        //   font-size: 1.5rem !important;
        // }
      }
    }
    .second {
      width: 100%;
      height: 480px;
      overflow: hidden;
      img {
        width: 100%;
        height: 480px;
      }
    }
    .third {
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      flex-direction: column;
      h1 {
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 35px;
      }
      p {
        margin-bottom: 35px;
        font-size: 22px;
        text-align: center;
      }
      .thirdBtns:hover {
        box-shadow: 0 8px 8px 0 rgb(130, 127, 127);
        transform: translate(0, -10px);
      }
    }
    .form-layer {
      width: 100%;
    }
    #map {
      margin-top: 30px;
      width: 100%;
      height: 300px;
      border: 1px solid gray;
      box-sizing: border-box;
      overflow: hidden;
    }
    /deep/ .el-dialog {
      min-width: 550px;
    }
    /deep/ .el-dialog__body {
      padding: 10px;
    }
  }
  .lasts {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(-1deg, #3c4255 0, #2a2f40 98%);
    padding: 10px 10px;
    h2 {
      font-size: 2rem;
      color: #fff;
      padding: 10px 0;
      text-align: center;
      margin: 30px 0;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
    a:hover {
      color: #286ea7;
      text-decoration: none;
    }
    .lastsTop {
      width: 100%;
      display: flex;
      justify-content: center;
      .lastsTop1 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 50px;
        h3 {
          font-weight: 400;
          font-size: 1.25vw;
          color: #ffffff;
          margin: 30px 0;
        }
        p {
          font-size: 1vw;
          line-height: 2.2;
          color: #ffffff;
        }
      }
      .lastsTop3 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 50px;
        h3 {
          font-weight: 400;
          font-size: 1.25vw;
          color: #ffffff;
          margin: 30px 0;
        }
        p {
          font-size: 1vw;
          line-height: 2.2;
          color: #ffffff;
        }
      }
      .lastsTop2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 50px;
        h3 {
          font-weight: 400;
          font-size: 1.25vw;
          color: #ffffff;
          margin: 30px 0;
        }
        p {
          font-size:1vw;
          line-height: 2.2;
          color: #ffffff;
        }
      }
      .lastsTop6 {
        width: 12%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 50px;
        h3 {
          font-weight: 400;
          font-size: 1.25vw;
          color: #ffffff;
          margin: 30px 0;
        }
        p {
          font-size: 1vw;
          line-height: 2.2;
          color: #ffffff;
        }
        img {
          width: 5rem;
          height: 5rem;
          margin-top: 5px;
        }
      }
    }
    .lastsTop7 {
      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 0.7rem;
        margin-top: 10px;
        padding-bottom: 10px;
        margin-right: 10px;
        li {
          padding: 0 5px;
          border-right: 1px solid #ccc;
        }
      }
    }
    .lastsMiddle {
      color: #fff;
      border-top: 3px solid #79797a;
      padding-top: 8px;
      text-align: center;
      font-size: 12px;
      a {
        color: #fff;
      }
      span {
        cursor: pointer;
      }
    }
  }
}
</style>

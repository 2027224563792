<template>
  <div class="n6">
    <h1 class="n6-title">{{title}}</h1>
    <p class="n6-info">{{info}}</p>
    <div class="n6-content">
      <ul>
        <li v-for="(item,index) of content" :key="index">
          <div
            class="n6-card"
            :class="{current:currentIndex===index}"
            @mouseenter="showCard(index)"
          >
            <div v-show="currentIndex!==index">
              <div class="n6-bgimg" :style="{backgroundImage:'url('+item.imgUrl+')'}">
                <h4 class="n6-caption">{{item.title}}</h4>
                <div class="n6-mask"></div>
                <h4 class="n6-caption">{{item.title}}</h4>
              </div>
              <div>
                <p>{{item.caption}}</p>
              </div>
            </div>
            <div v-show="currentIndex===index">
              <div class="current-bg" :style="{backgroundImage:'url('+item.imgUrl+')'}">
                <div class="n6-current-mask"></div>
                <h4 class="n6-current-caption">{{item.title}}</h4>
                <p class="current-desc">{{item.desc}}</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "n1",
  data() {
    return {
      currentIndex: 0,
      title: "安全服务",
      info: "强固保护，安心无忧。",
      content: [
        {
          title: "护网行动",
          imgUrl: require("../../assets/img/sofaFW/n6-bg1.png"),
          desc:
            "对设备告警流量进行分析及风险确认,对出现的安全漏洞给予修复建议,对出现的安全风险协助客户进行风险排查、定位、处置。"
        },
        {
          title: "安全竞赛",
          imgUrl: require("../../assets/img/sofaFW/n6-bg2.png"),
          desc:
            "竞赛内容涵盖网络安全基础知识、WEB安全、主机安全、协议分析、数据库安全和加解密隐写、数字取证、无线安全等。 竞赛形式由单兵挑战和CTF夺旗两种形式组成。"
        },
        {
          title: "代码审计",
          imgUrl: require("../../assets/img/sofaFW/n6-bg3.png"),
          desc:
            "分析源代码，充分挖掘代码中存在的安全缺陷以及规范性缺陷。找到普通安全测试所无法发现的如二次注入、反序列化、xml实体注入等安全漏洞。"
        },
        {
          title: "应急响应",
          imgUrl: require("../../assets/img/sofaFW/n6-bg4.png"),
          desc:
            "收集事件信息、分析网络活动相关程序，日志和数据，判断事件的严重性，评估出问题的严重等级，是否向上进行汇报等。"
        },
        {
          title: "安全运维",
          imgUrl: require("../../assets/img/sofaFW/n6-bg5.png"),
          desc:
            "负责网络、系统和业务等方面的安全加固工作，进行常规的安全扫描、渗透测试，进行安全工具和系统研发以及安全事件应急处理。"
        }
      ]
    };
  },
  methods: {
    showCard(index) {
      this.currentIndex = index;
    }
  }
};
</script>

<style scoped>
.n6 {
  text-align: center;
  background-size: cover;
  position: relative;
  background: linear-gradient(-1deg, #3c4255 0, #2a2f40 98%);
  height: 660px;
  color: #fff;
}
h1 {
  position: relative;
  display: inline-block;
  margin: 50px auto 20px;
  line-height: 30px;
  padding: 0 30px;
  font-weight: 400;
  font-size: 28px;
  color: #fff;
}
.n6-info {
  color: #fff;
}
h1::before {
  content: "";
  width: 66px;
  height: 13px;
  background: url(../../assets/img/sofaFW/n1_r.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 5px;
  left: 100%;
}
h1::after {
  content: "";
  width: 66px;
  height: 13px;
  background: url(../../assets/img/sofaFW/n1_l.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 5px;
  right: 100%;
}
.n6-content ul {
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: 60px;
  margin-bottom: 0;
}
.n6-content::after {
  content: "";
  clear: both;
  display: block;
}
.n6-card {
  position: relative;
  width: 200px;
  height: 390px;
  text-align: center;
  transition: opacity, height, margin 0.3s linear;
  overflow: hidden;
}
.n6-caption {
  position: absolute;
  color: #fff;
  z-index: 99;
  left: 0;
  right: 0;
  margin: auto;
  top: 40%;
}
.n6-current-caption {
  position: absolute;
  color: #fff;
  z-index: 99;
  left: 0;
  right: 0;
  margin: auto;
  top: 20%;
}
.current {
  position: relative;
  width: 280px;
  height: 380px;
  padding: 30px;
  margin-top: -25px;
  background-repeat: repeat;
  overflow: hidden;
}
.current-bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.77;
  background: center;
  background-size: 100% 100%;
}
.n6-bgimg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.77;
  background: center;
  background-size: 100% 100%;
}
.n6-caption::after {
  content: " ";
  display: block;
  width: 30px;
  height: 4px;
  background: #40acff;
  margin: 16px auto 0 auto;
}
.n6-current-caption::after {
  content: " ";
  display: block;
  width: 30px;
  height: 4px;
  background: #40acff;
  margin: 16px auto 0 auto;
}
.n6-caption-l::after {
  content: " ";
  display: block;
  width: 30px;
  height: 2px;
  background: #108ced;
  margin: 16px auto 0 auto;
}
.n6-icon-l {
  width: 30px;
}
.n6-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.77;
  background-image: linear-gradient(-180deg, #364167 0, #0a0b13 100%);
}
.current-desc {
  position: absolute;
  margin: 0;
  padding: 200px 30px;
  font-size: 14px;
  line-height: 24px;
  font-size: 12px;
}
.n6-current-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.82;
  background-image: linear-gradient(-180deg, #3f6493 0, #1c192b 100%);
}
</style>